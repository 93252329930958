<template>
  <div class="wrapper">
    <base-button
      icon
      type="primary"
      class="flotingBtn"
      :class="[isActive ? 'tagBtn2' : 'tagBtn']"
      @click="isActive = !isActive"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-right"></i></span>
    </base-button>
    <base-button
      icon
      type="primary"
      class="flotingBtn2"
      @click="toggleClass"
      :class="[isActive ? 'tagBtn' : 'tagBtn2']"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-left"></i></span>
    </base-button>
    <Sidebar :class="{ tagBtn: isActive }" />
    <div class="main-content" :class="{ DashboardBody: isActive }">
      <SampleNavbar />
      <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
        <b-breadcrumb>
          <b-breadcrumb-item href="#">Blog</b-breadcrumb-item>
          <b-breadcrumb-item href="#" active>Add Blog</b-breadcrumb-item>
        </b-breadcrumb>
      </base-header>
      <b-container fluid class="mt--7">
        <b-card type="default" header-classes="bg-transparent">
          <b-form style="text-align: left">
            <b-row>
              <b-col md="6">
                <label>Blog Name *</label>
                <b-input
                  @input="urlAdd(blog.name)"
                  v-model="blog.name"
                  type="text"
                  placeholder="Add Blog Name"
                ></b-input>
              </b-col>
              <b-col md="6">
                <label>Blog Link *</label>
                <b-input
                  v-model="blog.url"
                  type="text"
                  placeholder="Add Blog Link"
                  readonly
                />
              </b-col>
              <b-col md="6" class="mt-4">
                <label>Blog Image *</label>
                <b-input
                  v-model="blog.image"
                  type="text"
                  placeholder="Add Blog Link"
                />
              </b-col>
              <b-col md="6" class="mt-4">
                <label>Blog Category *</label>
                <b-input-group>
                  <b-form-select
                    v-model="blog.category"
                    :options="categoryList"
                  ></b-form-select>
                  <b-input-group-append>
                    <b-button
                      @click="$router.push({ name: 'blog-category' })"
                      variant="outline-primary"
                    >
                      <i class="fas fa-plus"></i>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col md="4" class="mt-4">
                <label>Meta Title (70 characters)</label>
                <b-input
                  v-model="blog.meta_title"
                  type="text"
                  placeholder="Add Meta Title"
                />
              </b-col>
              <b-col md="4" class="mt-4">
                <label>Meta Description (160 characters)</label>
                <b-input
                  v-model="blog.meta_des"
                  type="text"
                  placeholder="Add Meta Description"
                ></b-input>
              </b-col>
              <b-col md="4" class="mt-4">
                <label
                  >Meta Key Word (10 keyword phrases like(Keyword,
                  keyword))</label
                >
                <b-input
                  v-model="blog.meta_key"
                  type="text"
                  placeholder="Add Meta Key Word"
                />
              </b-col>
              <b-col md="12" class="mt-4">
                <label>Small Content *</label>
                <b-input
                  v-model="blog.small_content"
                  type="text"
                  placeholder="Add Small Content"
                />
              </b-col>
              <b-col md="12" class="mt-4">
                <label>Long Content *</label>
                <!-- <b-input type="text" v-model="blog.long_content" placeholder="Add Small Content"/> -->
                <v-md-editor
                  v-model="blog.long_content"
                  height="400px"
                ></v-md-editor>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="9"></b-col>
              <b-col md="3" class="mt-4"
                ><base-button block type="primary" @click="addBlog"
                  >Add Blog</base-button
                ></b-col
              >
            </b-row>
          </b-form>
        </b-card>
      </b-container>
      <Footer />
    </div>
  </div>
</template>
<script>
import Sidebar from "./../../Starter/Sidebar";
import SampleNavbar from "./../../Starter/SampleNavbar";
import Footer from "./../../Starter/SampleFooter";
import { API_URL } from "../../../router/api";
export default {
  name: "addBlog",
  props: ["data"],
  components: {
    Sidebar,
    SampleNavbar,
    Footer
  },
  data() {
    return {
      isActive: false,
      blog: {
        id: null,
        image: null,
        name: null,
        url: null,
        category: null,
        small_content: null,
        long_content: "",
        meta_title: null,
        meta_des: null,
        meta_key: null
      }
    };
  },
  computed: {
    categoryList() {
      return this.$store.state.newsCategoryList == null
        ? []
        : this.$store.state.newsCategoryList.filter(data => {
            return data.news_cat_status === 1;
          });
    }
  },
  methods: {
    toggleClass: function(event) {
      this.isActive = !this.isActive;
    },
    urlAdd(data) {
      let str = data;
      this.blog.url = str
        .toLowerCase()
        .trim()
        .split(/\s+/)
        .join("-");
    },
    addBlog() {
      const self = this;
      let api = {
        callBack() {
          self.axios
            .post(API_URL + "admin_news_add", self.blog)
            .then(response => {
              let obj = response.data;
              console.log(obj);
              obj.status == true
                ? self.$store.dispatch("alertSuccess", obj.message)
                : self.$store.dispatch("alertError", obj.message);
              self.$store.dispatch("getNewsShow");
            });
          self.blog = {};
        }
      };
      this.blog.name == null || this.blog.name == ""
        ? self.$store.dispatch("alertError", "Name Empty")
        : this.blog.image == null || this.blog.image == ""
        ? this.$store.dispatch("alertError", "Image Empty")
        : this.blog.category == null || this.blog.category == ""
        ? this.$store.dispatch("alertError", "category Empty")
        : this.blog.small_content == null || this.blog.small_content == ""
        ? this.$store.dispatch("alertError", "Small Details Empty")
        : this.blog.long_content == ""
        ? this.$store.dispatch("alertError", "Long Details Empty")
        : api.callBack();
    }
  },
  mounted() {
    console.log(this.data);
    this.blog = {
      id: this.data.news_id,
      image: this.data.news_image,
      name: this.data.news_title,
      url: this.data.news_url,
      category: this.data.news_cat_id,
      small_content: this.data.news_sub_title,
      long_content: this.data.news_content,
      meta_title: this.data.news_metaTitle,
      meta_des: this.data.news_metaDes,
      meta_key: this.data.news_metaKey
    };
  }
};
</script>
<style lang="scss">
.DashboardBody {
  margin-left: 0px !important;
}
.tagBtn {
  display: none !important;
}
.tagBtn2 {
  display: block !important;
}
.ve-fullscreen {
  z-index: 150001 !important;
}
</style>
